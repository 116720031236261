import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { mutate } from 'swr';
import Marquee from './Marquee';
import DangerousHTML from './DangerousHTML';
import getRouterPath from '../helpers/getRouterPath';
import { useAnnouncementBar } from '../hooks/app';
import { useScrollTop } from "../hooks/scroll"
import { isNewHomePageApplicable } from '../constants/feature';
const aPathArray = ['category', '', 'shop', 'brands', 'product', 'bundle'];

const AdvertBannerHeader = props => {
  const router = useRouter();
  const deliveryInfoRef = useRef(false);
  const { pathname } = router;
  const [hidden, setHidden] = useState(true);
  const { data } = useAnnouncementBar();
  // useEffect(() => {
  //   if (process.browser && deliveryInfoRef.current && data) {
  //     const r = document.querySelector(':root');
  //     const deliveryInfo = deliveryInfoRef.current;
  //     const deliveryInfoHeight = deliveryInfo.clientHeight;
  //     // r.style.setProperty('--delivery-info-height', `${deliveryInfoHeight}px`);
  //     r.style.setProperty('--delivery-info-height', `37px`);
  //     // const innerDiv = deliveryInfo.querySelector('.marquee');
  //     // if (innerDiv.scrollWidth > window.innerWidth) {
  //     //   setIsLongText(true);
  //     // }
  //   }
  //   return () => {
  //     const r = document.querySelector(':root');
  //     r.style.setProperty('--delivery-info-height', '0px');
  //   };
  // },[]);

  useEffect(() => {
    const r = document.querySelector(':root');
    if (aPathArray.includes(getRouterPath(pathname)) && data) {
      r.style.setProperty('--delivery-info-height', `37px`);
      setHidden(false)
    } else {
      r.style.setProperty('--delivery-info-height', `0px`);
      setHidden(true)
    }

    // if (process.browser && deliveryInfoRef.current) {
    // const r = document.querySelector(':root');
    // r.style.setProperty('--delivery-info-height', '0px');
    // }
  }, [pathname, data]);

  useEffect(() => {
    setHidden(!!sessionStorage.getItem('hideAnnouncement'));
  }, []);

  function hide() {
    if (process.browser) {
      const r = document.querySelector(':root');
      r.style.setProperty('--delivery-info-height', '0px');
    }
    setHidden(true);
    sessionStorage.setItem('hideAnnouncement', true);
  }

  if (data?.data === null) {
    if (process.browser) {
      const r = document.querySelector(':root');
      r.style.setProperty('--delivery-info-height', '0px');
    }
    return null;
  }

  if (!aPathArray.includes(getRouterPath(pathname))) {
    if (process.browser) {
      const r = document.querySelector(':root');
      r.style.setProperty('--delivery-info-height', '0px');
    }
    return null;
  }

  if (!data) {
    if (process.browser) {
      const r = document.querySelector(':root');
      r.style.setProperty('--delivery-info-height', '0px');
    }
    return null;
  }

  if (hidden) {
    if (process.browser) {
      const r = document.querySelector(':root');
      r.style.setProperty('--delivery-info-height', '0px');
    }
    return null;
  }
  return (
    <div
      ref={deliveryInfoRef}
      className="advert-banner-header"
      style={{ '--bgColor': data.background_color, '--color': data.text_color }}
    >
      {/* <div className={` marquee  ${isLongText ? 'animate' : ''}`}>
        <span className="m-inner">
          <DangerousHTML html={data.text} />
        </span>
        {isLongText && (
          <span className=" m-inner">
            <DangerousHTML html={data.text} />
          </span>
        )}
      </div> */}
      <Marquee>
        <DangerousHTML className="w-100 an-text" html={data.text} />
      </Marquee>
      <button type="button" className="a-button btn" onClick={hide}>
        <i className="icon-close a-close-btn" />
      </button>
    </div>
  );
};

export default AdvertBannerHeader;
