import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import notifications from '../constants/modalNotificationConst';
import appContext from '../Context/appContext';
import { useIdProof } from '../hooks/User';
import useAPI from '../NetworkCall/API';
import { pushOnTopIfNotPresent } from '../redux/slices/modalSlice';
import storageService from '../services/storageService';
import ActiveLink from './ActiveLink';
import { toggleUserDropdown } from '../redux/slices/userSlice';

export default function UserDropdown() {
  // const {
  //   // state: {
  //   //   user: { isLoggedIn, userInfo = {} } = {},
  //   //   cart: { cartData } = {} 
  //   // },
  //   dispatch
  // } = useContext(appContext);
  // const { dispatch } = useContext(appContext)
  const dispatchRedux = useDispatch();
  const cartData = useSelector(state => state.cart?.cartData) || {}
  const { isLoggedIn, userInfo = {} } = useSelector(state => state.user)
  const { logOut } = useAPI();

  const {
    data: { thumbnail_url: thumbnailUrl, selfie_thumbnail_url: selfieThumbnailUrl }
  } = useIdProof();

  const listing = [
    {
      name: 'My Orders',
      url: '/my-orders',
      icon: 'icon-profile-my-orders'
    },
    {
      name: 'My Info',
      url: '/profile/my-info',
      icon: 'icon-profile-my-info'
    },
    {
      name: 'Addresses',
      url: '/profile/addresses',
      icon: 'icon-profile-address'
    },
    {
      name: 'My Wallet',
      url: '/myaccount',
      icon: 'icon-profile-my-wallet',
      value: cartData?.walletAmount ? `$${cartData?.walletAmount}` : null
    },
    // {
    //   name: 'Referrals',
    //   url: '/referral',
    //   icon: 'icon-share-circles'
    // }
  ];

  const ref = useRef(null);

  const handleLogout = async () => {
    if (isLoggedIn) {
      await logOut();
      storageService.clearUserSpecificData();
      location.href = `/`;
    } else {
      dispatchRedux(pushOnTopIfNotPresent({ type: notifications.loginPopup }));
    }
  };

  useEffect(() => {
    const listener = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // dispatch({ type: 'toggleUserDropdown', payload: false });
        dispatchRedux(toggleUserDropdown(false))
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);

  useEffect(() => { }, [selfieThumbnailUrl, thumbnailUrl]);

  return (
    <div ref={ref} className="user-dropdown-parent container">
      <div className="user-dropdown-box long-user">
        {isLoggedIn ? (
          <div className="user-top-section logged-in">
            <div className="user-info d-flex align-items-center">
              <div className="user-pp">
                {selfieThumbnailUrl ? (
                  <img src={selfieThumbnailUrl} alt="selfie" className="h-100" />
                ) : (
                  <div className="icon icon-profile-my-info h-100 d-flex align-items-center justify-content-center w-100" />
                )}
              </div>
              <div className="user-detail ml-3">
                <h6>Hi{`${userInfo.first_name ? ', ' : ''}${userInfo.first_name}`}</h6>
              </div>
            </div>
          </div>
        ) : null}
        <div className="pt-2 pb-2">
          {listing.map(item => (
            <button
              key={item.name}
              className="btn no-text-decoration"
              onClick={e => {
                Router.push(item.url);
                // dispatch({ type: 'toggleUserDropdown', payload: false });
                dispatchRedux(toggleUserDropdown())
              }}
            >
              <div className="dd-item d-flex align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  <i className={`${item.icon} `} />
                  {item.name}
                </span>
                {item.value ? <span className="price-value fs-14 ml-2">{item.value}</span> : null}
              </div>
            </button>
          ))}
          {isLoggedIn ? (
            <div className="btn-wrap mb-2 mt-2">
              <button
                data-testid="#logout_btn"
                onClick={() => handleLogout()}
                type="button"
                className="btn btn-outline-primary btn-logout"
              >
                Log Out
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
