import React from 'react';
import Link from 'next/link';

const ProfileInHeader = ({ isLoggedIn }) => {
  return (
    <>
      <div className="profile-header">
        <Link href="/profile">
          <span className="icon-account-thin" />
        </Link>
      </div>
    </>
  );
};

export default ProfileInHeader;
