import React, { Fragment, useEffect, useState, useRef } from 'react';

const Marquee = ({
  style = {},
  className = '',
  play = true,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = 'left',
  speed = 20,
  delay = 0,
  loop = 0,
  gap = 40,
  children
}) => {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [isLongText, setIsLongText] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef(null);
  const marqueeRef = useRef(null);

  const calculateWidth = () => {
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
    }
    if (marqueeWidth - 1 < containerWidth) {
      setDuration(containerWidth / speed);
      setIsLongText(false);
      // console.log('sammll');
    } else {
      setIsLongText(true);
      setDuration((marqueeWidth + gap) / speed);
      // console.log('big');
    }
  };

  useEffect(() => {
    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Gradient color in an unfinished rgba format

  return (
    <Fragment>
      {!isMounted ? null : (
        <div
          ref={containerRef}
          style={{
            ...style,
            '--pause-on-hover': pauseOnHover ? 'paused' : 'running',
            '--pause-on-click': pauseOnClick ? 'paused' : 'running'
          }}
          className={`${className} marquee-container`}
        >
          <div
            ref={marqueeRef}
            style={{
              '--play': isLongText ? 'running' : 'paused',
              '--direction': direction === 'left' ? 'normal' : 'reverse',
              '--duration': `${duration}s`,
              '--delay': `${delay}s`,
              '--iteration-count': loop ? `${loop}` : 'infinite',
              '--marquee-gap': `${gap / 2}px`
            }}
            className={`marquee ${isLongText ? 'm-animate' : ''}`}
          >
            {children}
          </div>
          {isLongText && (
            <div
              style={{
                '--play': isLongText ? 'running' : 'paused',
                '--direction': direction === 'left' ? 'normal' : 'reverse',
                '--duration': `${duration}s`,
                '--delay': `${delay}s`,
                '--iteration-count': loop ? `${loop}` : 'infinite',
                '--marquee-gap': `${gap / 2}px`
              }}
              className={`marquee ${isLongText ? 'm-animate' : ''}`}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Marquee;
