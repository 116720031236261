import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Link from 'next/link';
import { useRouter, withRouter } from 'next/router';
import debounce from 'lodash/debounce';
import CSSTransition from 'react-transition-group/CSSTransition';
import HraderDeliveryAddress from './HraderDeliveryAddress';
import notificationTypes from '../../../constants/modalNotificationConst';
import appConfig from '../../../appConfig';
import ActiveLink from '../../ActiveLink';
import appContext from '../../../Context/appContext';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../../../constants/scheduleType';
import hideModalHeader from '../../../constants/hideModalHeader';
import { ASAP, SCHEDULE } from '../../../constants/deliveryType';
import { GANJAGODDESS } from '../../../constants/website';
import { useZipCodeStatus, useDeliveryDetails } from '../../../hooks/app';
import getRouterPath from '../../../helpers/getRouterPath';
import StorageService from '../../../services/storageService';
import AdvertBannerHeader from '../../AdvertBannerHeader';
import CartProductsWidget from '../../CartProductsWidget';
import DeliveryTypeToggle from '../../DeliveryTypeToggle';
import getDefaultTab from '../../../helpers/getDefaultTab';
import envAppConfig from '../../../constants/envAppConfig';
import ProfileInHeader from '../../profileAccount/ProfileInHeader';
import { isWishlistApplicable, isNewHomePageApplicable, isProfieModuleApplicable } from '../../../constants/feature';
import { useWalletAmount } from '../../../hooks/User';
import DeliveryTimingSchedule from '../../DeliveryTimingSchedule';
import DeliveryTiming from '../../DeliveryTiming';
import UserDropdown from '../../UserDropdown';
import Loader from '../../Loader';
import HappyHours from '../../HappyHours';
import CountDown from '../../CountDown';
import useAPI from '../../../NetworkCall/API';
import * as WEBSITE from '../../../constants/website';
import { useDispatch, useSelector } from 'react-redux';
import { pushIfNotPresent, pushNotification } from '../../../redux/slices/modalSlice';
import { toggleLoginDrawer, toggleSidebar, toggleUserDropdown } from '../../../redux/slices/userSlice';
import { setAllCartProductsWidget } from '../../../redux/slices/cartSlice';

const Header = ({
  isCartpage,
  hideCartIcon,
  centerComponent,
  hasWhiteBg,
  handleCancel,
  pageName,
  hasShadow,
  cancelOrder,
  centeredHeader,
  className,
  minimalNavbar,
  backPath,
  secondaryNavbarClassName,
  onClickFilter,
  showFilter,
  showLogin = false,
  filterApplied,
  setTab,
  defaultSearchTab,
  showProfile = false,
  hideTabsInError,
  initialDeliveryAddressDetails,
  initialFreightLimit = false,
  initialLocationAddress = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  userSection,
  deliveryData,
  asapProductsCount,
  scheduledProductsCount,
  showUserInfo = false,
  showDeliverySpecificMessage,
  isSearchPage,
  showHappyHours,
  hideTeslaCountDown = false,
  serverHost,
  help = false
}) => {
  // const {
  //   state: {
  //     // cart: {
  //     //   cartData: {
  //     //     cart_item_count: cartItemCount = 0
  //     //   } = {},
  //     //   updateProductWidget
  //     // },
  //     // user: { isLoggedIn, showUserDropdown },
  //     initialHistoryLength,
  //     // searchTabs
  //   },
  //   dispatch
  // } = useContext(appContext);
  const {
    cartData: {
      cart_item_count: cartItemCount = 0
    } = {},
    updateProductWidget
  } = useSelector(state => state.cart) || {}
  const { isLoggedIn, showUserDropdown, searchTabs, initialHistoryLength } = useSelector(state => state.user)
  const notifications = useSelector(state => state.modal.notifications)
  const dispatchRedux = useDispatch()
  const [animClass, setAnimClass] = useState('');

  const { getUpsellProducts, getUpsellRecommendedProducts } = useAPI(initialLocationAddress, initialFreightLimit, initialPurchaseLimit, initialUpsellProducts, initialDeliveryAddressDetails);

  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);
  const {
    data: { walletAmount },
    isLoading: isLoadingWallet
  } = useWalletAmount();
  const {
    data: { asapEnabled = true, scheduleEnabled, lastSlotCutOff, asapEndTime, asapStartTime, happyHours }
  } = useDeliveryDetails(initialDeliveryAddressDetails, true);
  const { is_enable: is_happy_hours_enable, end_time } = happyHours || {};

  const router = useRouter();
  const { pathname, query: { tab = getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled }), giftcard } = {} } =
    router;

  const [disabledLogin, setDisabledLogin] = useState('');
  const [count, setCount] = useState(cartItemCount);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleOn, setToggleOn] = useState(false);

  const showHeaderOn = ['category', '', 'shop', 'brands', 'categories', 'top-sellers-hub', 'weed-delivery-near', 'search'];
  const hideModalNotification = hideModalHeader.includes(getRouterPath(pathname));

  const debounced = useRef(debounce(cartCount => setCount(cartCount), 850, { leading: false, trailing: true }));

  const showWishlistIcon = !['/login', '/checkout', '/confirmation/[...orderId]'].includes(pathname);
  const isFirstRender = useRef(cartItemCount);
  useEffect(() => {
    if (!cartItemCount) return
    if ((isFirstRender.current === cartItemCount) || isFirstRender.current == 0) {
      isFirstRender.current = cartItemCount;
      return;
    }
    if ((isFirstRender.current < cartItemCount)) {
      setAnimClass(prevClass => (prevClass === 'anim-1' ? 'anim-2' : 'anim-1'));
    }
    isFirstRender.current = cartItemCount;
  }, [cartItemCount]);

  useEffect(() => {
    debounced.current(cartItemCount);
  }, [cartItemCount]);

  useEffect(() => {
    if (!StorageService.getAgeConfirmationShowPopup(true) && !StorageService.getUpdatedAgeConfirmationShowPopup()) {
      setDisabledLogin('disabled');
    } else {
      setDisabledLogin('');
    }
  }, [StorageService.getAgeConfirmationShowPopup(true), StorageService.getUpdatedAgeConfirmationShowPopup()]);

  useEffect(async () => {
    if (toggleOn) {
      updateProductWidget ? setIsLoading(false) : setIsLoading(true);
      await getUpsellProducts();
      await getUpsellRecommendedProducts();
      setIsLoading(false);
      setToggleOn(false);
    }
  }, [toggleOn, updateProductWidget]);

  useEffect(() => {
    const r = document.querySelector(':root');
    if (showHappyHours && is_happy_hours_enable) {
      r.style.setProperty('--hh-height', `40px`);
    } else {
      r.style.setProperty('--hh-height', `0px`);
    }
  }, [showHappyHours, is_happy_hours_enable]);

  function goBack() {
    if (window.history.length > initialHistoryLength) {
      router.back();
    } else {
      router.push('/');
    }
  }

  const routeData = StorageService.getRouteDetails();

  function goHome() {
    if (process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' && routeData) {
      window.location = routeData.origin;
    } else {
      router.push('/');
    }
  }

  function handleSideMenuClick() {
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())
  }
  const goToCart = useCallback(() => {
    if (validZipcode) {
      // Router.push('/cart');
      let deliveryType = SCHEDULE_TYPE_ASAP;
      const page = getRouterPath(pathname);
      if (page === 'scheduledshop' || tab == SCHEDULE || !asapEnabled) {
        deliveryType = SCHEDULE_TYPE_FUTURE;
      }
      // if (page === 'brands' && productType !== null) {
      //   deliveryType = productType;
      // }
      router.push({
        pathname: '/checkout',
        query: { deliveryType }
      });
    } else {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    }

  }, [validZipcode, asapEnabled, tab])
  // function goToCart() {
  // }

  const [showAllCartProductsWidget, setShowAllCartProductsWidget] = useState(false);

  const toggleCartWidget = useCallback(() => {
    setShowAllCartProductsWidget(true);
    setToggleOn(true);
    // dispatch({ type: 'setViewCartLoading', payload: true });
    // dispatch({ type: 'setAllCartProductsWidget', payload: true });
    dispatchRedux(setAllCartProductsWidget(true))
    // if (cartItems?.length) {
    //   setToggleOn(true);
    //   getCart();
    // } else {
    //   dispatch({ type: 'setViewCartLoading', payload: false });
    // }
  }, [])

  function openSearchPopup() {
    if (validZipcode) {
      dispatchRedux(pushIfNotPresent({ type: notificationTypes.searchModal, data: { defaultSearchTab } }))
    } else {
      const addressModalOpen = notifications
        .map(notification => notification.type)
        .includes(notificationTypes.deliverAddressPopup);
      if (!addressModalOpen) {
        dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
      }
    }
  }
  function renderUserInfo() {
    return (
      <ActiveLink
        className="cursor-pointer user-info-btn d-flex align-items-center"
        otherAttributes={{ 'data-testid': '#current_user_dropdown' }}
        onClick={() => {
          // dispatch({ type: isLoggedIn ? 'toggleUserDropdown' : 'toggleLoginDrawer' });
          // isLoggedIn?dispatchRedux(toggleUserDropdown()): dispatchRedux(toggle)
          dispatchRedux(isLoggedIn ? toggleUserDropdown() : toggleLoginDrawer())
        }}
      >
        <span className="icon icon-account-h vertical-align-middle" />
        {isLoggedIn ? (
          <>
            <div className="small-text ml-2">
              <p className="fs-12 mb-0">Credits</p>
              <p className="fs-14 bold mb-0">{isLoadingWallet ? <Loader /> : `$${walletAmount}`}</p>
            </div>
            <span className="icon-down ml-2" />
          </>
        ) : null}
      </ActiveLink>
    );
  }

  function renderCartItemCount() {
    if (hideCartIcon) {
      return null;
    }
    if (count > 0) {
      return (
        <ActiveLink
          href=""
          onClick={toggleCartWidget}
          className="cursor-pointer d-flex align-items-center cart-btn-info"
          otherAttributes={{ 'data-testid': '#header_cart_btn' }}
        >
          <span className="icon icon-cartdark-icon vertical-align-middle relative ml-2">
            <div className="item-add-count d-flex align-items-center justify-content-center">
              <span className="bold fs-12">{count}</span>
            </div>
          </span>
          <div className="animate-stars">
            <span className='icon-star'></span><span className='icon-star'></span><span className='icon-star'></span><span className='icon-star'></span>
          </div>
          <div className="animate-stars2">
            <span className='icon-star'></span><span className='icon-star'></span><span className='icon-star'></span><span className='icon-star'></span>
          </div>
          {/* {isNewHomePageApplicable ? (
            <>
              <div className="small-text">
                <p className="fs-12 mb-0">Cart Total</p>
                <p className="fs-14 bold mb-0">
                  {beforeTaxDiscountedAmount
                    ? `$${parseFloat(beforeTaxDiscountedAmount).toFixed(2)}`
                    : calculatedAmount
                    ? `$${parseFloat(calculatedAmount).toFixed(2)}`
                    : '$0.00'}
                </p>
              </div>
              <span className="icon-down ml-2" />
            </>
          ) : null} */}
        </ActiveLink>
      );
    }
    return (
      <ActiveLink
        href=""
        onClick={toggleCartWidget}
        className="cursor-pointer d-flex align-items-center cart-btn-info"
        otherAttributes={{ 'data-testid': '#header_cart_btn' }}
      >
        <span className="icon icon-cartdark-icon test vertical-align-middle relative ml-2" />
      </ActiveLink>
    );
  }

  function renderComponentForNewHeader() {
    if (pageName) {
      return <div className=" logo-text bold">{pageName}</div>;
    }
    if (process.env.NEXT_PUBLIC_APP === GANJAGODDESS) {
      return (
        <>
          <div className="main-logo d-none d-lg-block">
            <img data-testid="#main-logo-img" src={appConfig.HEADER_LOGO_COLOR} onClick={goHome} alt={appConfig.APP_NAME} />
          </div>

          <div className="main-logo d-block  d-lg-none lowell-mobile-logo">
            <img data-testid="#main-logo-img" src={appConfig.LOGO} onClick={goHome} alt={appConfig.APP_NAME} />
          </div>
        </>
      );
    }

    return (
      <div className={`main-logo ${[WEBSITE.GRASSDOOR, WEBSITE.BENTO].includes(process.env.NEXT_PUBLIC_APP) ? 'gd-logo' : ''}`}>
        <img
          data-testid="#main-logo-img"
          className="d-img"
          src={
            process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true'
              ? envAppConfig && envAppConfig[serverHost]
                ? envAppConfig[serverHost].HEADER_LOGO_COLOR
                : envAppConfig.grassdoor.HEADER_LOGO_COLOR
              : appConfig.HEADER_LOGO_COLOR
          }
          onClick={goHome}
          alt={appConfig.APP_NAME}
        />

        {[WEBSITE.GRASSDOOR, WEBSITE.BENTO].includes(process.env.NEXT_PUBLIC_APP) ? (
          <img
            data-testid="#main-logo-img"
            className="m-img"
            src={
              process.env.NEXT_PUBLIC_CHECKOUT_ONLY
                ? envAppConfig && envAppConfig[serverHost]
                  ? envAppConfig[serverHost].HEADER_LOGO_SHORT
                  : envAppConfig.grassdoor.HEADER_LOGO_SHORT
                : appConfig.HEADER_LOGO_SHORT
            }
            onClick={goHome}
            alt={appConfig.APP_NAME}
          />
        ) : null}
      </div>
    );
  }

  const getProfile = () => {
    if (isProfieModuleApplicable && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' && showProfile && isLoggedIn) {
      return <ProfileInHeader isLoggedIn={isLoggedIn} />;
    }
    return null;
  };

  return (
    <>
      <nav
        id="navbar"
        className={`navbar fixed-top header new-header ${hasShadow ? 'has-box-shadow' : ''}
         ${centeredHeader ? 'centered-header' : ''} ${className} ${hideModalNotification ? 'hide-features' : ''} ${minimalNavbar ? 'minimal-navbar' : ''
          }`}
      >
        <AdvertBannerHeader />

        <div className="container n-container bento-header">
          <div className="d-flex align-items-center">
            {backPath ? (
              <a className="btn left-btn" test-id="#icon-back" onClick={goBack}>
                <span className="icon-back d-lg-inline-block d-none" />
                <div className="icon-chevron-right-e fs-20 d-lg-none d-bock icon" />
              </a>
            ) : (
              <button type="button" className="btn left-btn" onClick={handleSideMenuClick} data-testid="#hamburger_icon">
                <span className="icon-hamburger icon-ham-menu" />
              </button>
            )}

            <div className="f-sec">{renderComponentForNewHeader()}</div>

            {showHeaderOn.includes(getRouterPath(pathname)) && (asapEnabled || scheduleEnabled) && !hideTabsInError ? (
              <DeliveryTypeToggle
                currentTab={tab}
                setTab={setTab}
                isAsapDisabled={false}
                isScheduleDisabled={!scheduleEnabled}
                hideToggle={!asapEnabled || !scheduleEnabled}
                asapProductsCount={asapProductsCount}
                scheduledProductsCount={scheduledProductsCount}
                className="d-none d-lg-block pl-3"
              />
            ) : null}

            {showDeliverySpecificMessage ? (
              <div className="delivery-specific-text">
                {tab == ASAP && asapEnabled ? (
                  <DeliveryTiming currentTab={tab} setTab={setTab} />
                ) : scheduleEnabled ? (
                  <DeliveryTimingSchedule />
                ) : null}
              </div>
            ) : null}
          </div>

          {isNewHomePageApplicable ? null : (
            <div className="s-sec">
              {!giftcard && !pathname.includes('/confirmation/') ? (
                <div className="s-sec-i">
                  <HraderDeliveryAddress className="p-0 text-left " />
                </div>
              ) : null}
            </div>
          )}

          <div className={`right-btn-container ${animClass} ${isNewHomePageApplicable ? 'updated' : ''}`}>
            {showHappyHours && is_happy_hours_enable ? (
              <div className="timer-sec d-none d-lg-block mr-5">
                <CountDown countDownEndTime={end_time} className="tooltip-data" happyHoursActiveBenefits={happyHours} />
              </div>
            ) : null}

            {showFilter && !isNewHomePageApplicable ? (
              <button
                test-id="#filter"
                type="button"
                className={`btn right-btn  d-lg-none hide-for-bento ${filterApplied ? 't-filter-indicator' : ''}`}
                onClick={onClickFilter}
              >
                <i className="icon-filter" />
              </button>
            ) : null}

            {!isNewHomePageApplicable && !hideModalNotification && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' ? (
              <button
                type="button"
                className="btn right-btn chat-btn  h-search-btn mr-0"
                test-id="#header_search_icon"
                onClick={openSearchPopup}
              >
                <i className="icon-search" />
              </button>
            ) : null}

            {cancelOrder ? (
              <button
                type="button"
                onClick={() => handleCancel()}
                data-testid="#cancel-order-button"
                className="btn right-btn cancel-order-btn cancel-order text-danger d-none d-lg-block "
              >
                Cancel Order
              </button>
            ) : null}

            {!isNewHomePageApplicable && appConfig.FAQ_URL && !hideCartIcon ? (
              <a href={`${appConfig.FAQ_URL}?faq=true`} rel="noreferrer" className="btn right-btn help-btn">
                <span className="help-btn">
                  <i className="icon-faq" />
                </span>
              </a>
            ) : null}

            {isWishlistApplicable && showWishlistIcon ? (
              // passHref is added so that Link href is pased to child
              // so it may be used for E2E testing
              // as Next <Link> is not rendered in client DOM
              <Link prefetch={false} href="/wishlist" passHref>
                <button data-testid="#wishlist-link" type="button" className="btn heart-btn">
                  <i className="icon icon-heart-outline " />
                </button>
              </Link>
            ) : null}

            {!isNewHomePageApplicable && isLoggedIn ? getProfile() : null}

            {!isNewHomePageApplicable && appConfig.FAQ_URL && isCartpage ? (
              <a href={`${appConfig.FAQ_URL}?faq=true`} rel="noreferrer" className="btn right-btn help-btn">
                <span className="h-inner">Help</span>
              </a>
            ) : null}

            {/* {help ? (
              <div
                data-testid="#chat-button"
                onClick={() => window.fcWidget.open({ conversationReferenceId: '1', name: 'Payment Issues’' })}
                className="help-icon-info"
              >
                <span className="icon icon-livechat-i" />
              </div>
            ) : null} */}

            {(showUserInfo && renderUserInfo()) || null}

            {renderCartItemCount()}

            {!isLoggedIn && showLogin && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' ? (
              <Link prefetch={false} href="/login">
                <a href="/login" className={`btn btn-primary h-login-btn d-none d-lg-inline-block ${disabledLogin}`}>
                  Login
                </a>
              </Link>
            ) : null}
          </div>
        </div>

        {/* <CSSTransition
          appear
          in={notificationsOverlap.length === 0 && notifications.length === 0 && showCartWidget && !searchTabs}
          mountOnEnter
          timeout={300}
          classNames="slideup-cart"
          unmountOnExit
          onExited={exitCartWidget}
        >
          <CartWidget goToCart={goToCart} setClickUnmount={setClickUnmount} />
        </CSSTransition> */}

        {/* <CSSTransition
          appear
          in={showAllCartProductsWidget}
          mountOnEnter
          timeout={300}
          classNames="slideup-cart"
          unmountOnExit
        > */}
        {showAllCartProductsWidget ? <CartProductsWidget goToCart={goToCart} isLoading={isLoading} setShowAllCartProductsWidget={setShowAllCartProductsWidget} showAllCartProductsWidget={showAllCartProductsWidget} /> : null}
        {/* </CSSTransition> */}

        <CSSTransition
          appear
          in={showUserDropdown && !searchTabs}
          mountOnEnter
          timeout={300}
          classNames="slideup-cart"
          unmountOnExit
        >
          <UserDropdown />
        </CSSTransition>

        {showHappyHours && is_happy_hours_enable ? (
          <div className="timer-sec s-nav-hh d-lg-none">
            <HappyHours className="class" showText={false} />
          </div>
        ) : null}
      </nav>

      {isNewHomePageApplicable ? null : (
        <div
          className={`secondary-navbar ${minimalNavbar ? 'minimal-navbar' : ''} ${secondaryNavbarClassName}  ${pathname.includes('/profile') ? 'profile' : ''
            }`}
        >
          {!giftcard &&
            !pathname.includes('/confirmation/') &&
            !pathname.includes('/profile') &&
            !pathname.includes('/gift-cards-redeem') ? (
            <HraderDeliveryAddress className=" text-left " />
          ) : null}
        </div>
      )}
    </>
  );
};

export default withRouter(Header);
